@import "app/global.scss";
.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  margin-bottom: 40px;
  a{
    color: #999999;
    text-decoration: none;
  }
}
