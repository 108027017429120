// sass 变量
$primary-color: #ad96bc;

// 自定义字体
@font-face {
  font-family: 'Gilroy-ExtraBold';
  src: url('./fonts/Gilroy-ExtraBold.ttf');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: url('./fonts/Gilroy-Light.otf');
}

@font-face {
  font-family: 'Roman';
  src: url('./fonts/Roman.ttf');
}
